import React from "react";
import Option from "./option";
import {Link} from "react-router-dom";
import DropdownMenu from "./dropdown";
import Logo from "../logo.png";
export default function Navbar({onClick}){
  function handleButtonClick(content){
    onClick(content);
  }

    return (

    <nav class=" fixed-top navbar navbar-expand-lg bg-body-tertiary">

    <div class="container-fluid row">
    
      <a class="navbar-brand col-md-3 fw-bold ms-5 px-5" href="#"><img src={Logo}  class="img-fluid  w-25 h-25 float-start" alt="Logo" /></a>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse  navbar-collapse  onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}" id="navbarNavDropdown" >

        <ul class="navbar-nav ps-5 me-3 ms-5 justify-content-end">
     
          <DropdownMenu  content="Company" btn1={<Option href='#' to="/about-us" onClick={() => handleButtonClick('About us')} btncontent='About us' />} btn2={<Option to="/what-we-do"href='#' onClick={() => handleButtonClick('What we do')} btncontent='What we do' />}/>
          {/* <Option href='#' to="/innovation-lab" onClick={() => handleButtonClick('Innovation Lab')} btncontent='Innovation Lab' /> */}
          {/* btn3={<Option  to="/services/javatraining"onClick={() => handleButtonClick('javatraining')} href='#' btncontent='Java Training In Nepal' />} */}
          <DropdownMenu content="Services" btn1={<Option  to="/services/AI/ML" onClick={() => handleButtonClick('AI/ML')} href='#' btncontent='AI Based Product Development' />}btn2={<Option to="/services/data-analytics" onClick={() => handleButtonClick('Data Analytics')} href='#' btncontent='Data Analytics' />}/>
          <DropdownMenu content="products" btn2={<Option  to="/diabetes-sugar-tracking-app"onClick={() => handleButtonClick('products')} href='#' btncontent='Blood Sugar Tracker' />} />
          <DropdownMenu content="Current Openings"btn2={<Option  to="/internship"onClick={() => handleButtonClick('internship')} href='#' btncontent='Internship' />}btn3={<Option  to="/careers"onClick={() => handleButtonClick('Careers')} href='#' btncontent='Jobs' />}/>
          <DropdownMenu content="Blogs"btn2={<Option  to="/apps-for-prediabetes"onClick={() => handleButtonClick('prediabetes')} href='#' btncontent='Apps For Prediabetes' />}btn3={<Option  to="/app-for-type2-diabetes"onClick={() => handleButtonClick('type2 diabetes')} href='#' btncontent='App for Type 2 Diabetes' />} btn4={<Option  to="/type-2-diabetes-patient-info"onClick={() => handleButtonClick('type2 diabetes patient info')} href='#' btncontent='Type 2 Diabetes Patient Info' />} btn5={<Option  to="/gdm-pregnancy"onClick={() => handleButtonClick('Gdm Pregnancy')} href='#' btncontent='Gdm Pregnancy' />}  />


        </ul>
       {/* <Link to="/contact-us"> <button type="button" class="btn btn-primary btn-lg px-4 py-1 ">Do You have Product Idea?</button></Link> */}
      </div>
    </div>
  </nav>
 );
}