import React, { useState } from 'react';
const DropdownMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };


  return ( 
           <li
            class={`nav-item dropdown ${isOpen ? 'show' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <a
              class="btn btn-sm btn-light text-primary"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded={isOpen}
            >
             <h5>{props.content}</h5>
            </a>
        
            <ul class={`dropdown-menu ${isOpen ? 'show' : ''}`}>
               <li>{props.btn1}</li> 
               <li>{props.btn2}</li>
               <li>{props.btn3}</li> 
               <li>{props.btn4}</li>
               <li>{props.btn5}</li>
            </ul>
         
          </li>
      
  );
};

export default DropdownMenu;